import { maxMin } from "../constants";
import { Legend } from "chart.js";

export const mapValuesToSaveData = (sectionId: number, values: any) => {
  console.log("Saving... ValuesToData", sectionId, values);
  const data: any = {};

  switch (sectionId) {
    case 1:
      if (values.technologies.length) {
        data.technology_id = values.technologies;
      }
      if (values.formFactors.length) {
        data.form_factor_id = values.formFactors;
      }
      if (values.fallbacks.length) {
        data.fallback_id = values.fallbacks;
      }
      if (values.chipsets.length) {
        data.chipset_id = values.chipsets;
      }
      if (values.supports.length) {
        data.support_id = values.supports;
      }
      if (values.deviceVendors.length) {
        data.device_vendor_id = values.deviceVendors;
      }
      if (values.frequencyBands.length) {
        data.frequency_band_id = values.frequencyBands;
      }
      if (values.frequencyBands.length) {
        data.frequency_band_control = values.frequencyBandControl;
      }
      if (values.commercial.length) {
        data.commercial = values.commercial;
      }
      if (values.startDate) {
        data.start_date = values.startDate;
      }
      if (values.endDate) {
        data.end_date = values.endDate;
      }
      break;

    case 2:
      if (values.technologies.length) {
        data.technology_id = values.technologies;
      }
      // network_type_id ?
      if (values.features.length) {
        data.feature_id = values.features;
      }
      if (values.statuses.length) {
        data.status_id = values.statuses;
      }
      if (values.featuresControl) {
        data.feature_control = values.featuresControl;
      }
      if (values.ituRegions.length) {
        data.itu_region_id = values.ituRegions;
      }
      if (values.regions.length) {
        data.region_id = values.regions;
      }
      if (values.childRegions.length) {
        data.childRegions = values.childRegions;
      }
      if (values.countries.length) {
        data.country_id = values.countries;
      }
      if (values.frequencyBands.length) {
        data.frequency_band_id = values.frequencyBands;
      }
      if (values.frequencyBands.length) {
        data.frequency_band_control = values.frequencyBandControl;
      }
      if (values.ue.length) {
        data.ue_speed = values.ue;
      }
      //data.publicStatus = values.publicStatus;
      if (values.startDate) {
        data.announced_from = values.startDate;
      }
      if (values.endDate) {
        data.announced_to = values.endDate;
      }
      if (values.spectrumBands.length) {
        data.spectrum_band_id = values.spectrumBands;
      }
      if (values.verticals.length) {
        data.vertical_id = values.verticals;
      }
      //data.subVerticals = values.subVerticals;
      if (values.satVendors.length) {
        data.satellite_vendor_id = values.satVendors;
      }
      if (values.switchoffTechs) {
        data.sunset_technology_id = values.switchoffTechs;
      }
      if (values.satelitentn.length) {
        data.satelitentn = values.satelitentn;
      }
      if (values.downloadSpeedControl !== "all") {
        data.download_speed = values.downloadSpeed;
        data.download_speed_control = values.downloadSpeedControl;
      }
      //data.ue_speed = values.ueSpeed;
      //data.ue_speed_control = values.ueSpeedControl;
      break;
      
    case 3:
      if (values.technologies.length) {
        data.technology_id = values.technologies;
      }
      if (values.customer_status.length) {
        data.customer_status = values.customer_status;
      }
      if (values.features.length) {
        data.feature_id = values.features;
      }
      if (values.statuses.length) {
        data.statuses = values.statuses;
      }
      if (values.featuresControl.length) {
        data.feature_control = values.featuresControl;
      }
      if (values.ituRegions.length) {
        data.itu_region_id = values.ituRegions;
      }
      if (values.regions.length) {
        data.region_id = values.regions;
      }
      if (values.childRegions.length) {
        data.childRegions = values.childRegions;
      }
      if (values.countries.length) {
        data.country_id = values.countries;
      }
      if (values.frequencyBands.length) {
        data.frequency_band_id = values.frequencyBands;
      }
      /*       if (values.frequencyBands.length) {
        data.frequency_band_control = values.frequencyBandControl;
      } */
      if (values.ue.length) {
        data.ue_speed = values.ue;
      }
      //data.publicStatus = values.publicStatus;
      if (values.startDate) {
        data.announced_from = values.startDate;
      }
      if (values.endDate) {
        data.announced_to = values.endDate;
      }
      if (values.spectrumBands.length) {
        data.spectrum_band_id = values.spectrumBands;
      }
      /*
            if (values.satVendors.length) {
        data.satellite_vendor_id = values.satVendors;
      }
      if (values.switchoffTechs) {
        data.sunset_technology_id = values.switchoffTechs;
      }
      if (values.satelitentn.length) {
        data.satelitentn = values.satelitentn;
      }
      data.switchoffTechs = values.switchoffTechs; */
      if (values.verticals.length) {
        data.vertical_id = values.verticals;
      }
      /*       data.verticals = values.verticals;
      data.subVerticals = values.subVerticals; */
      //data.download_speed = values.downloadSpeed;
      //data.download_speed_control = values.downloadSpeedControl;
      //data.ue_speed = values.ueSpeed;
      //data.ue_speed_control = values.ueSpeedControl;
      break;

    case 4:
      if (values.search) {
        data.search = values.search;
      }
      if (values.regions.length) {
        data.regions = values.regions;
      }
      if (values.childRegions.length) {
        data.regions = values.childRegions;
      }
      if (values.countries.length) {
        data.countries = values.countries;
      }
      if (values.publicStatus) {
        data.public_status_id = values.publicStatus;
      }
      if (values.startDate) {
        data.sunset_from = values.startDate;
      }
      if (values.endDate) {
        data.sunset_to = values.endDate;
      }
      if (values.switchoffTechs.length) {
        data.sunset_technology_id = values.switchoffTechs;
      }
      if (values.upgradeTechs.length) {
        data.upgrade_technology_id = values.upgradeTechs;
      }
      break;

    case 5:
      if (values.chipsetTypes.length) {
        data.chipset_type_id = values.chipsetTypes;
      }
      if (values.chipsetVendors.length) {
        data.chipset_vendor_id = values.chipsetVendors;
      }
      if (values.technologies.length) {
        data.technology_id = values.technologies;
      }
      if (values.supports.length) {
        data.support_id = values.supports;
      }
      if (values.ue.length) {
        data.ue_cat = values.ue;
      }
      if (values.startDate) {
        data.start_date = values.startDate;
      }
      if (values.endDate) {
        data.end_date = values.endDate;
      }
      if (values.commercial.length) {
        data.commercial = values.commercial;
      }
      break;

    case 6:
      if (values.ituRegions.length) {
        data.itu_region_id = values.ituRegions;
      }
      if (values.regions.length) {
        data.region_id = values.regions;
      }
      if (values.countries.length) {
        data.country_id = values.countries;
      }

      const [min, max] = values.frequencyBand === -1 ? [0, 100000] : values.frequencies[values.frequencyBand];

      console.log("values.frequencies[values.frequencyBand", values.frequencies);
      data.band_start = min;
      data.band_end = max;
      break;
  }

  return data;
};

export const mapSaveDataToValues = (sectionId: number, values: any) => {
  console.log("Restoring... DataToValues", sectionId, values);
  switch (sectionId) {
    case 1:
      return {
        technologies: values?.technology_id || [],
        formFactors: values?.form_factor_id || [],
        fallbacks: values?.fallback_id || [],
        chipsets: values?.chipset_id || [],
        deviceVendors: values?.device_vendor_id || [],
        frequencyBands: values?.frequency_band_id || [],
        frequencyBandControl: !!values?.frequency_band_control,
        commercial: values?.commercial || [],
        supports: values?.support_id || [],
        startDate: values?.start_date || null,
        endDate: values?.end_date || null,
      };

    case 2:
      return {
        technologies: values?.technology_id || [],
        features: values?.feature_id || [],
        featuresControl: !!values?.feature_control,
        statuses: values?.status_id || [],
        ituRegions: values?.itu_region_id || [],
        regions: values?.region_id || [],
        childRegions: values?.childRegions || [],
        countries: values?.country_id || [],
        frequencyBands: values?.frequency_band_id || [],
        frequencyBandControl: !!values?.frequency_band_control,
        ue: values?.ue_speed || [],
        downloadSpeed: values?.download_speed || 0,
        downloadSpeedControl: values?.download_speed_control || "all",
        //publicStatus: values?.public_status_id || 0,
        startDate: values?.start_date || null,
        endDate: values?.end_date || null,
        spectrumBands: values?.spectrum_band_id || [],
        satVendors: values?.satellite_vendor_id || [],
        satelitentn: values?.use_case_id || [],
        switchoffTechs: values?.sunset_technology_id || [],
        verticals: values?.vertical_id || [],
        //subVerticals: values?.subVerticals || [],
        //ueSpeed: values?.download_speed || 0,
        //ueSpeedControl: values?.download_speed_control || "all",
      };

    case 3:
      return {
        technologies: values?.technology_id || [],
        features: values?.feature_id || [],
        customer_status: values?.customer_status || [],
        featuresControl: !!values?.feature_control,
        statuses: values?.statuses || [],
        ituRegions: values?.itu_region_id || [],
        regions: values?.region_id || [],
        childRegions: values?.childRegions || [],
        countries: values?.country_id || [],
        frequencyBands: values?.frequency_band_id || [],
        frequencyBandControl: !!values?.frequency_band_control,
        ue: values?.ue_speed || [],
        downloadSpeed: values?.download_speed || 0,
        downloadSpeedControl: values?.download_speed_control || "all",
        publicStatus: values?.public_status_id || 0,
        startDate: values?.start_date || null,
        endDate: values?.end_date || null,
        spectrumBands: values?.spectrumBands || [],
        satVendors: values?.satVendors || [],
        satelitentn: values?.satelitentn || [],
        switchoffTechs: values?.switchoffTechs || [],
        verticals: values?.verticals || [],
        subVerticals: values?.subVerticals || [],
        //ueSpeed: values?.download_speed || 0,
        //ueSpeedControl: values?.download_speed_control || "all",
      };

    case 4:
      return {
        search: values?.search || null,
        regions: values?.regions || [],
        childRegions: values?.regions || [], //these come as regions
        countries: values?.countries || [],
        publicStatus: values?.public_status_id || 1,
        startDate: values?.sunset_from || null,
        endDate: values?.sunset_to || null,
        switchoffTechs: values?.sunset_technology_id || [],
        upgradeTechs: values?.upgrade_technology_id || [],
      };

    case 5:
      return {
        chipsetTypes: values?.chipset_type_id || [],
        chipsetVendors: values?.chipset_vendor_id || [],
        supports: values?.support_id || [],
        technologies: values?.technology_id || [],
        ue: values?.ue_cat || [],
        startDate: values?.start_date || null,
        endDate: values?.end_date || null,
        commercial: values?.commercial || [],
      };

    case 6:
      const band = maxMin.reduce((f, item, index) => (values?.band_start >= item[0] && values?.band_end <= item[1] ? index : f), 0);
      const frequencies = maxMin.map(arr => arr.map(arr1 => arr1));
      frequencies[band][0] = values?.band_start || frequencies[band][0];
      frequencies[band][1] = values?.band_end || frequencies[band][1];

      return {
        ituRegions: values?.itu_region_id || [],
        regions: values?.region_id || [],
        countries: values?.country_id || [],
        frequencyBand: band,
        frequencies,
      };
  }
};
