import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import FilterListItem from "../FilterListItem";
import { useGetVerticalsQuery } from "../../store/apis/search";
import { TitleType } from "../../types/entities";
import makeStyles from "@mui/styles/makeStyles";

export interface VerticalFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  value: any[];
  placeholder?: string;
  open?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  setOpen: (value: boolean) => void;
  onChange: (value: any[]) => void;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const VetticalsFilter: React.FC<VerticalFilterProps> = ({
  title = "verticals",
  name = "verticals-filter",
  tooltip,
  value = [],
  open,
  icon,
  setOpen,
  onChange,
}) => {
  const classes = useStyles();

  const { data: verticalsOptions = [], isLoading: isVerticalsLoading } = useGetVerticalsQuery();
  const [subVerticalsOptions, setsubVerticalsOptions] = React.useState<TitleType[]>([]);

  const [verticalValue, setverticalValue] = React.useState("");
  const [subVerticalValue, setsubVerticalValue] = React.useState("");
  const handleChange = (event: any) => {
    setverticalValue(event.target.value);
  };
  const handleChange2 = (event: any) => {
    setsubVerticalValue(event.target.value);
  };

  // conditionally set subVerticalsOptions
  React.useEffect(() => {
    // console.log("verticalValue", verticalValue);
    if (verticalValue) {
      const verticalObject = verticalsOptions.find(item => item.id === parseInt(verticalValue));
      // console.log("verticalObject", verticalObject);
      if (verticalObject) {
        setsubVerticalsOptions(verticalObject.subvertical);
      }
      setsubVerticalValue("");
    }
    // eslint-disable-next-line
  }, [verticalValue]);

  // emit value change to parent component
  React.useEffect(() => {
    const newValue: any[] = [];
    if (verticalValue) {
      newValue.push(verticalValue);
      if (subVerticalValue) {
        newValue.push(subVerticalValue);
      } else {
        const verticalObject = verticalsOptions.find(item => item.id === parseInt(verticalValue));
        verticalObject?.subvertical.forEach(item => {
          newValue.push(item.id);
        });
      }
    }
    onChange(newValue);
    // eslint-disable-next-line
  }, [verticalValue, subVerticalValue]);

  return (
    <>
      <FilterListItem title={title} icon={icon} open={open} tooltip={tooltip} setOpen={setOpen} selected={value.flat().length}>
        <Box marginBottom={4} id="verticals-filter">
          <FormControl
            variant="filled"
            className={classes.formControl}
            disabled={isVerticalsLoading}
            fullWidth
            style={{ margin: "10px 0px 10px 0px" }}
          >
            <InputLabel id="vertical">Vertical</InputLabel>
            <Select labelId="vertical" id={`${name}-parent`} value={verticalValue} onChange={handleChange} data-testid="verticalsDropdown">
              <MenuItem key={`m1-`} value={""}>
                ---
              </MenuItem>
              {verticalsOptions.map(option => (
                <MenuItem key={`vertical${option.id}`} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {subVerticalsOptions.length > 0 && (
            <>
              <FormControl variant="filled" className={classes.formControl} fullWidth style={{ margin: "10px 0px 10px 0px" }}>
                <InputLabel id="subvertical">Subvertical</InputLabel>
                <Select labelId="subvertical" id={`${name}-parent`} value={subVerticalValue} onChange={handleChange2}>
                  <MenuItem key={`m2-`} value={""}>
                    ---
                  </MenuItem>
                  {subVerticalsOptions.map(option => (
                    <MenuItem key={`subvertical${option.id}`} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Grid container spacing={2}>
            <Grid item>
              <Button
                id={`${name}-clear`}
                variant="outlined"
                size="small"
                onClick={() => {
                  setverticalValue("");
                  setsubVerticalValue("");
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FilterListItem>
    </>
  );
};

export default VetticalsFilter;
