import React, { useRef, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

export interface BarChartItem {
  label: string;
  value: number;
}

export interface BarChartProps {
  data: BarChartItem[];
  setRef?: (value: any) => void;
}

const BarChart: React.FC<BarChartProps> = ({ data, setRef }) => {
  const ref = useRef(null);
  const theme = useTheme();

  const labels = data.map(item => item.label);
  const values = data.map(item => item.value);

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const chart = {
    labels,
    datasets: [
      {
        data: values,
        barPercentage: 1,
        categoryPercentage: 0.85,
        backgroundColor: theme.palette.bar3,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    events: [],
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: false,
      },
    },
    animation: {
      onComplete: function (chartInstance: any) {
        //console.log("chartInstance", chartInstance);
        drawValues(chartInstance);
      },
    },
  };

  const drawValues = (chartInstance: any) => {
    const meta = chartInstance.chart._sortedMetasets[0].data;
    const ctx = chartInstance.chart._sortedMetasets[0].controller._ctx; //chartInstance.chart.ctx;
    ctx.textAlign = "center";
    ctx.font = "bold 8px";
    ctx.fillStyle = "#ffffff";

    values.forEach((value, i) => {
      ctx.fillText(value.toLocaleString(), meta[i].x, meta[i].y + 16, 200);
    });
  };

  useEffect(() => {
    if (setRef && ref.current) {
      setRef(ref.current);
    }
  }, [setRef]);

  return (
    <>
      <Box maxWidth={1280} marginY={8} width="100%">
        <Box height={340} marginBottom={8} width="100%">
          <Bar data={chart} options={options} ref={ref} />
        </Box>
      </Box>
    </>
  );
};

export default BarChart;
