import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import { NetworksValuesType } from "../../utils";
import NetworkTreeChartByVertical from "./NetworksTreeChartByVertical";
import NetworkTreeChartByCountry from "./NetworksTreeChartByCountry";

interface NetworkTreeChartProps {
  filters: NetworksValuesType;
  loading?: boolean;
}

const NetworkTreeChart: React.FC<NetworkTreeChartProps> = ({ filters, loading }) => {
  const [type, setType] = useState("vertical");
  
  return (
    <Box paddingY={4}>
      <Box marginY={16}>
        <RadioGroup
          name="type"
          value={type}
          row
          onChange={(_, value) => {
            setType(value);
          }}
        >
          <FormControlLabel value="vertical" control={<Radio color="primary" />} label="By Verticals" />
          <FormControlLabel value="country" control={<Radio color="primary" />} label="By Country" />
        </RadioGroup>
      </Box>
      {type === "vertical" ? (
        <NetworkTreeChartByVertical filters={filters} loading={loading} />
      ) : (
        <NetworkTreeChartByCountry filters={filters} loading={loading} />
      )}
    </Box>
  );
};

export default NetworkTreeChart;
