import { createAsyncThunk, createSelector, createSlice, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";

import { getRequest } from "../../services/apiService";
import { AppStateType } from "..";
import { AllocationType, CountryType, RegionType } from "../../types";
import { allocationsValuesToParams } from "../../utils";
import { maxMin } from "../../constants";

export interface AllocationsCountryType {
  id: number;
  allocations: AllocationType[];
}

export interface AllocationsValuesType {
  ituRegions: number[];
  regions: number[];
  childRegions: number[];
  countries: number[];
  frequencyBand: number;
  frequencies: number[][];
}

export const nextCountry = createAsyncThunk<void, { countryId: number; values: AllocationsValuesType }, { state: AppStateType }>(
  "allocations/nextCountry",
  async ({ countryId, values }, { getState, dispatch }) => {
    const country = selectAllocationsByCountry(countryId)(getState());
    const loading = selectAllocationsLoading(getState());

    if (!country && loading !== countryId) {
      dispatch(loadCountry({ countryId, values }));
    }
  }
);

export const loadCountry = createAsyncThunk<
  void,
  { countryId: number | undefined; values: AllocationsValuesType },
  { state: AppStateType }
>("allocations/loadCountry", async ({ countryId, values }, { dispatch }) => {
  if (countryId) {
    dispatch(setLoading(countryId));

    const response = await getRequest(
      `allocations?return_format=json&include_future2=0${allocationsValuesToParams({
        ituRegions: values.ituRegions,
        regions: values.regions,
        countries: [countryId],
        frequencyBand: values.frequencyBand,
        frequencies: values.frequencies,
      })}`
    );

    dispatch(setLoading(null));

    if (response?.results) {
      dispatch(addCountry({ id: countryId, allocations: response.results }));
    }
  }
});

export const initialValues: AllocationsValuesType = {
  ituRegions: [],
  regions: [],
  childRegions: [],
  countries: [],
  frequencyBand: -1,
  frequencies: maxMin,
};

const initialRegion: RegionType = {
  parent_id: 0,
  parent_title: "",
  title: "",
  id: 0,
};

interface initialStateType {
  values: AllocationsValuesType;
  lastSaved: AllocationsValuesType;
  download: string;
  loading: number | null;
  allSubRegions: RegionType[];
  filteredSubRegions: RegionType[];
}

const initialState: initialStateType = {
  values: initialValues,
  lastSaved: initialValues,
  loading: null,
  download: "",
  allSubRegions: [initialRegion], // Array of RegionType
  filteredSubRegions: [initialRegion], // Array of RegionType
};

const countriesAdapter = createEntityAdapter<AllocationsCountryType>({
  //@ts-ignore
  selectId: (countries: CountryType) => countries.id,
  sortComparer: (a, b) => a.id - b.id,
});

const allocationsSlice = createSlice({
  name: "allocations",
  initialState: countriesAdapter.getInitialState({
    ...initialState,
  }),
  reducers: {
    setValues: (state, { payload }: PayloadAction<AllocationsValuesType>) => {
      state.loading = null;
      countriesAdapter.removeAll(state);
      state.values = payload;
    },
    setSavedValues: (state, { payload }: PayloadAction<AllocationsValuesType>) => {
      state.lastSaved = payload;
    },
    resetValues: state => {
      state.values = initialValues;
    },
    setLoading: (state, { payload }: PayloadAction<number | null>) => {
      state.loading = payload;
    },
    addCountry: (state, { payload }: PayloadAction<AllocationsCountryType>) => {
      countriesAdapter.upsertOne(state, payload);
    },
    setAllSubRegions: (state, { payload }: PayloadAction<RegionType[]>) => {
      // console.log(payload);
      state.allSubRegions = payload;
    },
    setSubRegions: (state, { payload }: PayloadAction<number[]>) => {
      // console.log(payload);
      //console.log(state.allSubRegions.filter(region => payload.indexOf(region.parent_id) === -1));
      state.filteredSubRegions =
        payload.length === 0 ? state.allSubRegions : state.allSubRegions.filter(region => payload.indexOf(region.parent_id) !== -1); //region.parent_id === payload);
    },
  },
});

export const { setValues, addCountry, setLoading, resetValues, setSavedValues, setAllSubRegions, setSubRegions } = allocationsSlice.actions;

export const selectAllocationsSlice = (state: AppStateType) => state.allocations;

export const selectAllocationsValues = createSelector(selectAllocationsSlice, state => state.values);

export const selectAllocationsLoading = createSelector(selectAllocationsSlice, state => state.loading);

export const selectAllocationsByCountry = (countryId: number) =>
  createSelector(selectAllocationsSlice, state => countriesAdapter.getSelectors().selectById(state, countryId));

export const selectAllocations = createSelector(selectAllocationsSlice, state => countriesAdapter.getSelectors().selectAll(state));

export const selectAllocationsIds = createSelector(selectAllocationsSlice, state => countriesAdapter.getSelectors().selectIds(state));

export default allocationsSlice.reducer;
