import { createAsyncThunk, createSelector, createSlice, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";

import { getRequest } from "../../services/apiService";
import { OperatorType, RegionType } from "../../types";
import { AppStateType } from "../";
import { OperatorsValuesType } from "../../utils";

export interface OperatorsFiltersResponseType {
  total: number;
  results: OperatorType[];
}

export const initialValues: OperatorsValuesType = {
  search: "",
  countries: [],
  regions: [],
  childRegions: [],
  publicStatus: 1,
  startDate: null,
  endDate: null,
  switchoffTechs: [],
  upgradeTechs: [],
};

export const fetchOperators = createAsyncThunk<OperatorsFiltersResponseType>("operators/fetch", async () => {
  const response = await getRequest(`operators?return_format=json`);

  return { total: response?.count || 0, results: response?.results || [] };
});

export const getOperators = createAsyncThunk<void, void, { state: AppStateType }>("operators/get", (_, { dispatch, getState }) => {
  const hasFetched = selectOperatorsHasFetched(getState());

  if (!hasFetched) {
    dispatch(fetchOperators());
  }
});

interface OperatorsSliceType {
  loading: boolean;
  values: OperatorsValuesType;
  lastSaved: OperatorsValuesType;
  hasFetched: boolean;
  filter: string;
  sortBy: string;
  desc: boolean;
  rowsPerPage: number;
  page: number;
  total: number;
  allSubRegions: RegionType[];
  filteredSubRegions: RegionType[];
}

const initialRegion: RegionType = {
  parent_id: 0,
  parent_title: "",
  title: "",
  id: 0,
};

const operatorsAdapter = createEntityAdapter<OperatorType>({
  //@ts-ignore
  selectId: (operator: OperatorType) => operator.id,
  sortComparer: (a: any, b: any) => a.title.localeCompare(b.title),
});

export const operatorsSlice = createSlice({
  name: "operators",
  initialState: operatorsAdapter.getInitialState<OperatorsSliceType>({
    loading: false,
    values: initialValues,
    lastSaved: initialValues,
    total: 0,
    rowsPerPage: 20,
    page: 1,
    hasFetched: false,
    sortBy: "title",
    desc: false,
    filter: "",
    allSubRegions: [initialRegion], // Array of RegionType
    filteredSubRegions: [initialRegion], // Array of RegionType
  }),
  reducers: {
    updateOperator: (state, { payload }: PayloadAction<OperatorType>) => {
      operatorsAdapter.upsertOne(state, payload);
    },
    setRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
      state.page = 1;
    },
    setValues: (state, { payload }: PayloadAction<OperatorsValuesType>) => {
      state.values = payload;
      state.page = 1;
    },
    setSavedValues: (state, { payload }: PayloadAction<OperatorsValuesType>) => {
      state.lastSaved = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setFilter: (state, { payload }: PayloadAction<string>) => {
      state.filter = payload;
      state.page = 1;
    },
    setOrder: (state, { payload }: PayloadAction<string>) => {
      if (payload !== state.sortBy.valueOf()) {
        state.sortBy = payload;
        state.desc = true;
        state.page = 1;
      } else {
        state.desc = !state.desc.valueOf();
      }
    },
    setAllSubRegions: (state, { payload }: PayloadAction<RegionType[]>) => {
      state.allSubRegions = payload;
    },
    setSubRegions: (state, { payload }: PayloadAction<number[]>) => {
      //console.log(state.allSubRegions.filter(region => payload.indexOf(region.parent_id) === -1));
      state.filteredSubRegions =
        payload.length === 0 ? state.allSubRegions : state.allSubRegions.filter(region => payload.indexOf(region.parent_id) !== -1); //region.parent_id === payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOperators.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchOperators.fulfilled, (state, { payload }) => {
      const { results, total } = payload;

      state.loading = false;
      state.total = total;
      state.page = 1;
      state.hasFetched = true;

      if (results) {
        operatorsAdapter.setAll(state, results);
      }
    });
    builder.addCase(fetchOperators.rejected, state => {
      state.loading = false;
    });
  },
});

export const { setRowsPerPage, setPage, setOrder, setFilter, setValues, updateOperator, setSavedValues, setSubRegions, setAllSubRegions } =
  operatorsSlice.actions;

const operatorsSelectors = operatorsAdapter.getSelectors();

export const selectOperatorsSlice = (state: AppStateType) => state.operators;

export const selectOperatorsHasFetched = createSelector(selectOperatorsSlice, state => state.hasFetched);

export const selectOperatorsTotal = createSelector(selectOperatorsSlice, state => state.total);

export const selectOperatorsFilter = createSelector(selectOperatorsSlice, state => state.filter);

const selectFilterLowerCase = createSelector(selectOperatorsFilter, filter => filter.toLocaleLowerCase());

const selectOperatorsSorted = createSelector(selectOperatorsSlice, state =>
  operatorsSelectors
    .selectAll(state)
    .slice()
    .sort((a, b) => (state.desc ? -1 : 1) * a.title.localeCompare(b.title))
);

export const selectOperatorsFilteredBySearch = createSelector([selectOperatorsSorted, selectFilterLowerCase], (operators, filter) =>
  filter.length
    ? operators
        .filter(item => item.title.toLocaleLowerCase().indexOf(filter) > -1)
        .sort((a, b) => a.title.toLocaleLowerCase().indexOf(filter) - b.title.toLocaleLowerCase().indexOf(filter))
    : operators
);

export default operatorsSlice.reducer;
