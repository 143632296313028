import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import PieChartIcon from "@mui/icons-material/PieChart";
import MapIcon from "@mui/icons-material/Map";

import {
  AppBar,
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { Helmet } from "react-helmet-async";

import ContentWrapper from "../components/ContentWrapper";
import ExportCsv from "../components/ExportCsv";
import FilterNav from "../components/FilterNav";
import NetworksPieChart from "../components/NetworksPieChart";
import NoResults from "../components/NoResults";
import PageHeading from "../components/PageHeading";
import TableAdvanced from "../components/TableAdvanced";
import { networksCells } from "../constants";
import allRoutes from "../constants/routes";
import { useSaveSearchMutation } from "../store/apis/save";
import { useGetNetworksQuery, useLazyExportPublicNetworksQuery } from "../store/apis/search";
import { selectSaveToken } from "../store/slices/auth";
import { selectNetworksSlice, setPage, setRowsPerPage, setSavedValues, setSortBy } from "../store/slices/networks";
import { selectUiState, setDense, setFontSize } from "../store/slices/ui";
import { mapValuesToSaveData } from "../utils";
import { makeStyles } from "@mui/styles";
import HeatMap from "../components/HeatMap";
import { isEqual } from "lodash";
import SaveSearch from "../components/SaveSearch";

const useAppBarStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
  },
  positionSticky: {
    [theme.breakpoints.up("lg")]: {
      top: 52,
    },
  },
}));
const PublicNetworksSearch: React.FC = () => {
  const dispatch = useDispatch();
  const appBarStyles = useAppBarStyles();
  const { view } = useParams();
  const { dense, fontSize, exportFields } = useSelector(selectUiState);
  const { sortBy, sortDesc, page, rowsPerPage, values, lastSaved } = useSelector(selectNetworksSlice);
  const token = useSelector(selectSaveToken);
  const [format, setFormat] = useState("chart_region");
  const [trigger, { data: download, isLoading: isExporting }] = useLazyExportPublicNetworksQuery();

  const location = useLocation();
  const lastPart = location.pathname.split("/").pop() || "search";

  const [viewCurrent, setView] = useState(lastPart);
  const [saveSearch, { isLoading: isSaving }] = useSaveSearchMutation();

  const {
    data = { results: [], total: 0 },
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetNetworksQuery({
    rowsPerPage,
    page,
    values,
    sortBy,
    sortDirection: sortDesc ? "desc" : "asc",
    format: view === "pie" ? format : "json",
  });

  const { results = [], total = 0 } = data;
  const loading = isLoading || isFetching || isUninitialized;
  const pages = Math.ceil(total / rowsPerPage);

  let firstLoad = true;
  useEffect(() => {
    if (lastPart !== viewCurrent && !loading) {
      setView(lastPart);
    }
  }, [results, loading, lastPart, viewCurrent]);

  if (firstLoad) {
    // dispatch(resetValues());
  }

  const handleSave = () => {
    dispatch(setSavedValues(values));
    saveSearch({ sectionId: 2, data: mapValuesToSaveData(2, values) });
  };

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(newValue);
    if (newValue === "pie") {
      navigate(`${allRoutes.publicNetworks.path}/search/pie`);
    } else if (newValue === "map") {
      navigate(`${allRoutes.publicNetworks.path}/search/map`);
    } else if (newValue === "search") {
      navigate(`${allRoutes.publicNetworks.path}/search`);
    }
  };

  const renderButtons = () => (
    <Box paddingY={4}>
      <Grid container spacing={4} alignItems="center">
        <Grid item zeroMinWidth>
          <SaveSearch disabled={isEqual(values, lastSaved)} loading={isSaving} onClick={handleSave} />
        </Grid>
        <Grid item zeroMinWidth>
          <ExportCsv source={download} loading={isExporting} onClick={() => trigger({values: values, fields: exportFields})} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box width="100%">
      <Helmet title="Networks - GSA GAMBoD" />
      <FilterNav />
      <PageHeading
        title={allRoutes.publicNetworks.label}
        icon={allRoutes.publicNetworks.icon}
        color={allRoutes.publicNetworks.color}
        section="nts"
        inset
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link component={NavLink} to={allRoutes.dashboard.path}>
            Dashboard
          </Link>
          <Link component={NavLink} to={allRoutes.publicNetworks.path}>
            {allRoutes.publicNetworks.label}
          </Link>
          <Typography>Search</Typography>
        </Breadcrumbs>
      </PageHeading>
      <ContentWrapper inset>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs zeroMinWidth>
            <Box paddingY={10}>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography variant="h4">Your Results</Typography>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography variant="subtitle2" gutterBottom>
                    Returned results
                  </Typography>
                  <Typography variant="h4">
                    <span data-testid="networksTotal" data-testvalue={total}>
                      {total.toLocaleString()}
                    </span>{" "}
                    Networks
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item zeroMinWidth sx={{ display: { xs: "none", md: "block" } }}>
            {renderButtons()}
          </Grid>
        </Grid>
      </ContentWrapper>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ContentWrapper inset>
          <Divider />
          {renderButtons()}
        </ContentWrapper>
      </Box>
      <Divider />
      <TabContext value={lastPart}>
        <AppBar position="sticky" component="div" classes={appBarStyles}>
          {loading ? (
            <Box width="100%" position="relative" height={4}>
              <LinearProgress />
            </Box>
          ) : (
            <Box width="100%" position="relative" style={{ background: "#fff" }} height={4}></Box>
          )}
          <ContentWrapper body inset>
            <Box paddingTop={3}>
              <Tabs value={lastPart} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab
                  label="Table"
                  value="search"
                  data-testid="table-tab"
                  icon={<CalendarViewMonthIcon />}
                  iconPosition="start"
                  style={{
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
                <Tab
                  label="Pie Chart"
                  value="pie"
                  data-testid="pie-chart-tab"
                  icon={<PieChartIcon />}
                  iconPosition="start"
                  style={{
                    minWidth: "180px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
                <Tab
                  label="Penetration map"
                  value="map"
                  data-testid="map-tab"
                  icon={<MapIcon style={{ marginRight: 11 }} />}
                  iconPosition="start"
                  style={{
                    justifyContent: "flex-start",
                    minWidth: "180px",
                    paddingLeft: isTablet ? "40px" : "20px",
                    paddingRight: isTablet ? "40px" : "20px",
                  }}
                />
              </Tabs>
            </Box>
            <Divider />
          </ContentWrapper>
        </AppBar>
        <ContentWrapper body inset>
          <TabPanel value="pie">
            {lastPart !== viewCurrent ? (
              <></>
            ) : !loading && !results.length ? (
              <NoResults />
            ) : (
              <NetworksPieChart data={results} type={format} loading={loading} setType={setFormat} />
            )}
          </TabPanel>

          <TabPanel value="map">
            <HeatMap filterValues={values} loading={loading} />
          </TabPanel>

          <TabPanel value="search">
            {lastPart !== viewCurrent ? (
              <></>
            ) : !loading && !results.length ? (
              <NoResults />
            ) : (
              <TableAdvanced
                title={`GAMBoD NTS Search - ${total.toLocaleString()} results, page ${page}`}
                dense={dense}
                rows={results}
                cellsMap={networksCells(sortBy, sortDesc, values, false)}
                page={page}
                pages={pages}
                fontSize={fontSize}
                rowsPerPage={rowsPerPage}
                loading={loading}
                setRowsPerPage={value => {
                  dispatch(setRowsPerPage(value));
                }}
                setPage={value => {
                  dispatch(setPage(value));
                }}
                setFontSize={value => {
                  dispatch(setFontSize(value));
                }}
                setDense={value => {
                  dispatch(setDense(value));
                }}
                onOrder={value => {
                  dispatch(setSortBy(value));
                }}
              />
            )}
          </TabPanel>
        </ContentWrapper>
      </TabContext>
    </Box>
  );
};

export default PublicNetworksSearch;
