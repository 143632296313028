import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useGetVerticalsQuery } from "../../store/apis/search";
import { useDispatch, useSelector } from "react-redux";
import { selectNetworksSlice, setVertical } from "../../store/slices/privateNetworks";
import makeStyles from "@mui/styles/makeStyles";

export interface TreeChartProps {
  title?: string;
  data: any[];
  cutoutPercentage?: number;
  rotation?: number;
  displayLegend?: boolean;
  loading?: boolean;
  valueSuffix?: string;
  colors: string[];
  setRef?: any; //(value: Doughnut) => void;
}

/* const series = [
  {
    data: [
      {
        x: 'New Delhi',
        y: 218,
        fillColor: '#ee238c'
      },
      {
        x: 'Kolkata',
        y: 149
      },
    ]
  }
] */

const TreeChart: React.FC<TreeChartProps> = ({ data = [] }) => {
  const { data: verticalsOptions = [], isLoading: isVerticalsLoading } = useGetVerticalsQuery();
  const dispatch = useDispatch();
  const { values } = useSelector(selectNetworksSlice);
  const verticalsWithChildren = verticalsOptions.filter(item => item.subvertical.length>0)
  const isVerticalSelected = verticalsWithChildren.find(item => item.id === values.verticals[0])
  const curVertical = (isVerticalSelected?.title ?? "")
  console.log("curVertical", curVertical, values.verticals)
  
  //const noSuverticalName = curVertical === "" ? "aaaaVertical" : "bbbb"
  const [noSubverticals, setnoSubverticals] = useState("")
  
  const options = {
    treemap: {
      dataLabels: {
        format: "truncate",
      },
    },
    legend: {
      show: false,
    },
    chart: {
      height: 560,
      events: {
        // @ts-ignore
        click: function (event, chartContext, opts) {
          const elementIndex = opts.dataPointIndex;
          if(data[0].data[elementIndex]){
            const countryName = data[0].data[elementIndex].x
            const selectedVertical = verticalsWithChildren.find(item=> item.title === countryName)
            if (selectedVertical) {
              setnoSubverticals("")
              dispatch(setVertical([selectedVertical.id]));
            } else {
              setnoSubverticals(countryName);
            }
          } 
        },
      },
    },
    title: {
      text: " - ",
    },
    dataLabels: {
      distributed: true,
      style: {
        fontSize: "24px",
      },
      formatter: (input: string): string => (input.length > 10 ? `${input.substring(0, 10)}...` : input),
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "14px",
      },
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.5,
        reverseNegativeShade: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 5,
              color: "#ecdc4c",
            },
            {
              from: 5.001,
              to: 10,
              color: "#e9f288",
            },
            {
              from: 10.001,
              to: 20,
              color: "#c4dd4d",
            },
            {
              from: 20.001,
              to: 30,
              color: "#28f814",
            },
            {
              from: 30.001,
              to: 50,
              color: "#19c736",
            },
            {
              from: 50.001,
              to: 60,
              color: "#11a768",
            },
            {
              from: 60.001,
              to: 70,
              color: "#174eeb",
            },
            {
              from: 70.001,
              to: 100,
              color: "#3260fb",
            },
            {
              from: 100.001,
              to: 150,
              color: "#d500f1",
            },
            {
              from: 150.001,
              to: 300,
              color: "#de2bc1",
            },
          ],
        },
      },
    }
  };
  const seeAll = () => {
    dispatch(setVertical([]));
  }
  
  const useButtonStyles = makeStyles(() => ({
    label: {
      whiteSpace: "nowrap",
    },
  }));
  const buttonStyles = useButtonStyles();
  
  return (
    <>
      <Box maxWidth={1280} width="100%">
        {noSubverticals && !curVertical && <Box height={30} style={{ position: "absolute", zIndex: 5555, marginTop: "0px" }}><strong>{noSubverticals}</strong> has no subverticals.</Box>}
        {values.verticals.length > 0  && curVertical && <Box height={30} style={{ position: "absolute", zIndex: 5555, marginTop: "-7px" }}>
          Showing subverticals for <strong>{curVertical}</strong> &nbsp; 
          <Button
            classes={buttonStyles}
            variant="contained"
            onClick={seeAll}>See all verticals
          </Button>
        </Box>}
        {values.verticals.length === 0 && curVertical && <Box height={30} style={{ position: "absolute", zIndex: 5555, marginTop: "-4px" }}>
          <strong>{curVertical}</strong> doesn't have any subverticals.
        </Box>}
        <Box height={[720, 720, 640, 560]} marginBottom={8} marginTop={-15} width="100%">
          <ReactApexChart type="treemap" options={options} color="#ee238c" series={data} height={560} />
        </Box>
      </Box>
    </>
  );
};

export default TreeChart;
