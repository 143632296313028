import { Box, /*Link, CircularProgress,*/ Divider, Link, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import allRoutes from "../../constants/routes";
import { useDeleteSearchMutation, useGetSearchesQuery, useSaveSearchMutation, useUpdateSearchTitleMutation } from "../../store/apis/save";
import { AllocationsValuesType, setValues as setAllocationsValues } from "../../store/slices/allocations";
import { getApiToken, selectSaveToken, selectUsername } from "../../store/slices/auth";
import { setValues as setChipsetsValues } from "../../store/slices/chipsets";
import { setValues as setDevicesValues, setSavedValues } from "../../store/slices/devices";
import { setValues as setNetworksValues } from "../../store/slices/networks";
import { setValues as setOperatorsValues } from "../../store/slices/operators";
import { SaveType } from "../../types";
import { ChipsetsValuesType, DevicesValuesType, NetworksValuesType, OperatorsValuesType, mapSaveDataToValues, mapValuesToSaveData } from "../../utils";
import ContentWrapper from "../ContentWrapper";
import SaveItem from "../SaveItem";
import { Savings } from "@mui/icons-material";

export interface SaveListProps {
  sectionId?: number;
}

const SaveList: React.FC<SaveListProps> = ({ sectionId }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const token = useSelector(selectSaveToken);
  const username = useSelector(selectUsername);
  const { data = [], error, isUninitialized, isLoading } = useGetSearchesQuery({ token, sectionId }, { skip: !token });
  const [deleteSearch] = useDeleteSearchMutation();
  const [updateSearchTitle] = useUpdateSearchTitleMutation();
  const [searchToUpdate, setsearchToUpdate] = React.useState<null | SaveType>(null);
  const [open, setOpen] = React.useState(false)
  ;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (error && username) {
      dispatch(getApiToken(username));
    }
  }, [dispatch, error, username]);

  const onRun = (id: number) => {
    const item = data.find(item => item.id === id);

    if (item) {
      switch (item.section_id) {
        case 1:
          dispatch(setDevicesValues(mapSaveDataToValues(item.section_id, item.parameters) as DevicesValuesType));
          navigate(`${allRoutes.devices.path}/search`);
          break;

        case 2:
          dispatch(setNetworksValues(mapSaveDataToValues(item.section_id, item.parameters) as NetworksValuesType));
          navigate(`${allRoutes.publicNetworks.path}/search`);
          break;

        case 3:
          dispatch(setNetworksValues(mapSaveDataToValues(item.section_id, item.parameters) as NetworksValuesType));
          navigate(`${allRoutes.privateNetworks.path}/search`);
          break;

        case 4:
          dispatch(setOperatorsValues(mapSaveDataToValues(item.section_id, item.parameters) as OperatorsValuesType));
          navigate(`${allRoutes.operators.path}/search`);
          break;

        case 5:
          dispatch(setChipsetsValues(mapSaveDataToValues(item.section_id, item.parameters) as ChipsetsValuesType));
          navigate(`${allRoutes.chipsets.path}/search`);
          break;

        case 6:
          // @ts-ignore
          dispatch(setAllocationsValues(mapSaveDataToValues(item.section_id, item.parameters) as AllocationsValuesType));
          navigate(`${allRoutes.spectrum_assignment.path}/search`);
          break;
      }
    }
  };
  const onRename = (id: number) => {
    console.log(id)
    const myItem = data.find(item => item.id === id);
    if (myItem) setsearchToUpdate(myItem)
    handleClickOpen();
  };

  const onlyOneSection = () => {
    return (
      <>
        <Divider />
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <SaveItem
              title={item.title}
              parameters={item.parameters}
              sectionId={item.section_id}
              description={item.description}
              category={item.section_title}
              date={item.created_at}
              onDelete={() => deleteSearch({ id: item.id, token })}
              onRun={() => onRun(item.id)}
              onRename={() => onRename(item.id)}
            />
            <Divider />
          </React.Fragment>
        ))}
      </>
    );
  };
  const showSection = (section_id: number) => {
    const filtered = data.filter(a => a.section_id === section_id);
    if (filtered.length > 0)
      return (
        <>
          <Typography variant="h4" style={{ marginBottom: "15px" }}>
            {section_id === 1 && "Devices"}
            {section_id === 2 && "Public Networks"}
            {section_id === 3 && "Private Networks"}
            {section_id === 4 && "Operators"}
            {section_id === 5 && "Chipsets"}
            {section_id === 6 && "Spectrum Assignments"}
          </Typography>
          <Divider />
          {filtered.map((item, index) => (
            <React.Fragment key={index}>
              <SaveItem
                title={item.title}
                parameters={item.parameters}
                sectionId={item.section_id}
                description={item.description}
                category={item.section_title}
                date={item.created_at}
                onDelete={() => deleteSearch({ id: item.id, token })}
                onRun={() => onRun(item.id)}
                onRename={() => onRename(item.id)}
              />
              <Divider />
            </React.Fragment>
          ))}
          <br />
          <br />
        </>
      );
  };

  return (
    <ContentWrapper body inset>
      {sectionId && (
        <Box marginY={16} width="80%">
          <Typography variant="h3" gutterBottom>
            Saved Searches
          </Typography>
          <Typography>
            Searches can be saved and will show on the list below, so you can re-run searches later without having to re-select filters each
            time.
          </Typography>
        </Box>
      )}
      <Box marginY={16} width="80%">
        {isUninitialized || isLoading || error ? (
          <>{/* <CircularProgress /> */}</>
        ) : data.length > 0 ? (
          <>{sectionId ? onlyOneSection() : [1, 2, 3, 4, 5, 6].map(i => showSection(i))}</>
        ) : (
          <Typography>No saved searches.</Typography>
        )}
      </Box>
      {sectionId && (
        <Box marginY={16} width="100%" maxWidth={800}>
          <Typography>
            <Link href={allRoutes.my_searches.path}>See all saved Searches</Link>
          </Typography>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const newTitle = formJson.newTitle;
            console.log(newTitle);

            if(searchToUpdate){
              console.log("saving"+JSON.stringify(searchToUpdate))
              updateSearchTitle({id: searchToUpdate.id, data: { title: newTitle} });
            }
            handleClose();
          },
        }}
      >
        <Box marginY={8}>
          <DialogTitle>Rename search</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a new label for your search:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="newTitle"
              label=""
              type="text"
              defaultValue={searchToUpdate?.title}
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <Box marginRight={8}>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit">Save</Button>
          </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </ContentWrapper>
  );
};

export default SaveList;
