import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import FilterListItem from "../FilterListItem";

export interface MonthRangeFilterProps {
  title: string;
  name?: string;
  tooltip?: string;
  startDate?: number;
  endDate?: number;
  value: (number | null)[];
  disabled?: boolean;
  open?: boolean;
  setOpen: (open: boolean) => void;
  onChange: (value: (number | null)[]) => void;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MonthRangeFilter: React.FC<MonthRangeFilterProps> = ({
  title = "Date Range Filter",
  name = "date-range-filter",
  tooltip,
  startDate = 0,
  endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime(),
  value = [null, null],
  disabled,
  open,
  setOpen,
  onChange,
}) => {
  const [fromDate, toDate] = value;

  const classes = useStyles();
  const [startYear, setstartYear] = React.useState(2010);
  const [endYear, setendYear] = React.useState(new Date(new Date().getFullYear()).getTime());
  const [startMonth, setstartMonth] = React.useState(1);
  const [endMonth, setendMonth] = React.useState(1);
  const handleChange = (event: any) => {
    setstartYear(event.target.value);
    const startObject = new Date(event.target.value, startMonth - 1);
    const endObject = new Date(endYear, endMonth - 1);
    //console.log(event.target.value, startMonth - 1, startObject, startObject.valueOf());
    onChange([startObject.valueOf(), endObject.valueOf()]);
  };
  const handleChange2 = (event: any) => {
    setendYear(event.target.value);
    const startObject = new Date(startYear, startMonth - 1);
    const endObject = new Date(event.target.value, endMonth - 1);
    //console.log(event.target.value, endMonth - 1, endObject, endObject.valueOf());
    onChange([startObject.valueOf(), endObject.valueOf()]);
  };
  const handleChange3 = (event: any) => {
    setstartMonth(event.target.value);
    const startObject = new Date(startYear, event.target.value - 1);
    const endObject = new Date(endYear, endMonth - 1);
    //console.log(startYear, event.target.value, startObject, startObject.valueOf());
    onChange([startObject.valueOf(), endObject.valueOf()]);
  };
  const handleChange4 = (event: any) => {
    setendMonth(event.target.value);
    const startObject = new Date(startYear, startMonth - 1);
    const endObject = new Date(endYear, event.target.value - 1);
    //console.log(endYear, event.target.value, endObject, endObject.valueOf());
    onChange([startObject.valueOf(), endObject.valueOf()]);
  };

  const generateYears = (startYear: number) => {
    const years = [];
    for (var i = startYear; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  };
  const generateMonths = () => {
    const months = [];
    for (var i = 1; i <= 12; i++) {
      months.push(i);
    }
    return months;
  };

  const yearOptions = generateYears(2010);
  const monthOptions = generateMonths();

  /*   React.useEffect(() => {
    console.log("start", startYear, startMonth);
    console.log("end", endYear, endMonth);
  }, [startYear, startMonth, endYear, endMonth]); */

  return (
    <FilterListItem title={title} tooltip={tooltip} selected={0} open={open} setOpen={setOpen}>
      <Grid container spacing={4}>
        <Grid item>
          <FormControl variant="filled" className={classes.formControl} style={{ width: "90px" }}>
            <InputLabel id="startYear">Start Year</InputLabel>
            <Select labelId="startYear" id={`${name}-fromYear`} value={startYear} onChange={handleChange}>
              {yearOptions.map(option => (
                <MenuItem key={`m1${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="filled" className={classes.formControl} style={{ width: "60px" }}>
            <InputLabel id="startMonth">Month</InputLabel>
            <Select labelId="startMonth" id={`${name}-fromMonth`} value={startMonth} onChange={handleChange3}>
              {monthOptions.map(option => (
                <MenuItem key={`m1${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="filled" className={classes.formControl} style={{ width: "90px" }}>
            <InputLabel id="endYear">End Year</InputLabel>
            <Select labelId="endYear" id={`${name}-toYear`} value={endYear} onChange={handleChange2}>
              {yearOptions.map(option => (
                <MenuItem key={`m2${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="filled" className={classes.formControl} style={{ width: "60px" }}>
            <InputLabel id="endMonth">Month</InputLabel>
            <Select labelId="endMonth" id={`${name}-toMonth`} value={endMonth} onChange={handleChange4}>
              {monthOptions.map(option => (
                <MenuItem key={`m2${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box paddingTop={4}>
        <Button
          id={`${name}-clear`}
          variant="outlined"
          size="small"
          disabled={disabled || (fromDate === null && toDate === null)}
          onClick={() => {
            onChange([null, null]);
          }}
        >
          Clear
        </Button>
      </Box>
    </FilterListItem>
  );
};

export default MonthRangeFilter;
