import { Box, Button, Divider } from "@mui/material";
import { DeleteForever, GetApp, NetworkCheck, Info, Place, Public, SignalCellularAlt, Update, Waves } from "@mui/icons-material";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import { useFormik } from "formik";
import { isEqual } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../constants/routes";
import { useSaveSearchMutation } from "../../store/apis/save";
import {
  useGetChildRegionsQuery,
  useGetCountriesQuery,
  useGetFeaturesQuery,
  useGetFrequencyBandsAllQuery,
  useGetItuRegionsQuery,
  useGetRegionsQuery,
  useGetSatNTNQuery,
  useGetSatVendorsQuery,
  useGetSunsetTechsQuery,
  useGetTechnologiesQuery,
  useGetTechnologiesByNameQuery,
  useGetUeCategoriesQuery,
  useGetStatusesQuery,
} from "../../store/apis/search";
import { selectSaveToken } from "../../store/slices/auth";
import {
  initialValues,
  selectNetworksSlice,
  setAllSubRegions,
  setLastPage,
  setValues as setNetworksValues,
  setSavedValues,
  setSubRegions,
} from "../../store/slices/networks";
import { closeNetworksFilters, selectUiState, setFiltersOpen, setNetworksFilters } from "../../store/slices/ui";
import { IdType, ParentType } from "../../types";
import { mapValues, mapValuesToSaveData } from "../../utils";
import FilterList from "../FilterList";
import FrequenciesFilter from "../FrequenciesFilter";
import RadioFilter from "../RadioFilter";
import SearchFilter from "../SearchFilter";
import SpeedFilter from "../SpeedFilter";

const PublicNetworksForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { networks: filters } = useSelector(selectUiState);
  const { values: valuesCurrent, lastSaved, lastPage, filteredSubRegions } = useSelector(selectNetworksSlice);
  const token = useSelector(selectSaveToken);

  const { values, setFieldValue, setValues, handleSubmit, handleReset } = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(setLastPage(pathname));
      const { path } = routes.publicNetworks;

      dispatch(setNetworksValues(values));
      dispatch(setFiltersOpen(false));

      if (pathname === path) {
        navigate(`${path}/search`);
      }
    },
  });

  const { data: ituRegionsOptions = [], isLoading: isItuRegionsLoading } = useGetItuRegionsQuery();
  const { data: satVendorsOptions = [], isLoading: isSatVendorsLoading } = useGetSatVendorsQuery();
  const { data: satelitentnOptions = [], isLoading: isSatNTNLoading } = useGetSatNTNQuery();
  const { data: regionsOptions = [], isLoading: isRegionsLoading } = useGetRegionsQuery();
  const { data: childRegionOptions = [], isLoading: isChildRegionsLoading } = useGetChildRegionsQuery();
  const { data: countriesOptions = [], isLoading: isCountriesLoading } = useGetCountriesQuery({
    ituRegions: values.ituRegions,
    regions: values.regions,
  });
  //const { data: technologiesOptions = [], isLoading: isTechnologiesLoading } = useGetTechnologiesQuery();
  const { data: technologiesOptions = [], isLoading: isTechnologiesLoading } = useGetTechnologiesByNameQuery("public_network");
  const { data: featuresOptions = [], isLoading: isFeaturesLoading } = useGetFeaturesQuery();
  const { data: statusOptions = [], isLoading: isStatusesLoading } = useGetStatusesQuery();
  const { data: ueOptions = [], isLoading: ueIsLoading } = useGetUeCategoriesQuery();
  const { data: frequencyBandsOptions = [[], [], []], isLoading: isFrequencyBandsLoading } = useGetFrequencyBandsAllQuery({
    technologies: values.technologies,
    sectionId: 2,
  });
  const { data: switchoffTechnologiesOptions = [], isLoading: isSunsetTechnologiesLoading } = useGetSunsetTechsQuery();

  const [saveSearch] = useSaveSearchMutation();

  const [options1, options2, options3] = frequencyBandsOptions;

  useEffect(() => {
    const frequencyBandsFiltered = values.frequencyBands.filter(option => {
      return mapValues(options1).includes(option) || mapValues(options2).includes(option) || mapValues(options3).includes(option);
    });

    if (!isEqual(frequencyBandsFiltered, values.frequencyBands)) {
      setFieldValue("frequencyBands", frequencyBandsFiltered);
    }
  }, [options1, options2, options3, values.frequencyBands, setFieldValue]);

  useEffect(() => {
    // console.log("set all subRegions");
    if (!isChildRegionsLoading) {
      dispatch(setAllSubRegions(childRegionOptions));
      dispatch(setSubRegions([]));
    }
  }, [childRegionOptions, dispatch, isChildRegionsLoading]);

  useEffect(() => {
    setValues(valuesCurrent);
  }, [setValues, valuesCurrent]);

  useEffect(() => {
    const countriesFiltered = values.countries.filter(option => mapValues(countriesOptions).includes(option));

    if (!isEqual(countriesFiltered, values.countries)) {
      setFieldValue("countries", countriesFiltered);
    }
  }, [countriesOptions, values.countries, setFieldValue]);

  const setFilterOpen = useCallback(
    (key: string, open: boolean) => {
      dispatch(setNetworksFilters({ ...filters, [key]: open }));
    },
    [dispatch, filters]
  );

  const handleSave = () => {
    dispatch(setSavedValues(valuesCurrent));
    saveSearch({
      sectionId: 2,
      data: mapValuesToSaveData(2, valuesCurrent),
    });
  };

  //Create a copy of the technologies options so we can change data
  let technologiesOptionsForUI = technologiesOptions.map(item => Object.assign({}, item));

  let radioFilterSelectedItem: number = -1;

  if (values.technologies.length > 0) {
    radioFilterSelectedItem = values.technologies[0];
  }

  // Taking a single parent type (technogoloy item) and creating a list of technology options revelant to the parent type
  function mapSelectionToTechnologiesList(value: ParentType): IdType[] {
    const singleOption = technologiesOptions.filter(option => option.id === value.id);
    return singleOption;
  }

  // this sets what radio option is selected
  let valueForRadioButton = technologiesOptions.find(item => item.id === radioFilterSelectedItem);

  function CanSearch() {
    if (!isEqual(lastPage, pathname)) {
      return true;
    } else {
      if (!isEqual(values, valuesCurrent)) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <FilterList
      disabled={
        isRegionsLoading ||
        isChildRegionsLoading ||
        isCountriesLoading ||
        isTechnologiesLoading ||
        isFeaturesLoading ||
        isFrequencyBandsLoading
      }
      canSearch={CanSearch()}
      canSave={!isEqual(valuesCurrent, lastSaved)}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onSave={handleSave}
    >
      <Box paddingX={4} paddingY={2} textAlign="right" bgcolor="gunmetal.450">
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            dispatch(closeNetworksFilters());
          }}
        >
          Close all
        </Button>
      </Box>
      <Divider />
      <RadioFilter
        title="Technologies"
        name="technologies-filter"
        tooltip="Filter by Technology Type"
        value={valueForRadioButton}
        icon={<SignalCellularAlt fontSize="small" />}
        options={technologiesOptionsForUI}
        loading={isTechnologiesLoading}
        open={filters.technologies}
        onChange={value =>
          value ? setFieldValue("technologies", mapValues(mapSelectionToTechnologiesList(value))) : setFieldValue("technologies", [])
        }
        setOpen={value => setFilterOpen("technologies", value)}
      />
      <SearchFilter
        title="Features"
        name="features-filter"
        tooltip="Network features supported"
        value={featuresOptions.filter(option => values.features.includes(option.id))}
        icon={<NetworkCheck fontSize="small" />}
        options={featuresOptions}
        loading={isFeaturesLoading}
        disabled={!featuresOptions.length}
        open={filters.features}
        setOr={value => setFieldValue("featuresControl", value)}
        orValue={values.featuresControl}
        onChange={value => setFieldValue("features", mapValues(value))}
        setOpen={value => setFilterOpen("features", value)}
      />
      <SearchFilter
        title="Statuses"
        name="statuses-filter"
        tooltip="Network status"
        value={statusOptions.filter(option => values.statuses.includes(option.id))}
        icon={<Info fontSize="small" />}
        options={statusOptions}
        loading={isFeaturesLoading}
        disabled={!statusOptions.length}
        open={filters.statuses}
        onChange={value => setFieldValue("statuses", mapValues(value))}
        setOpen={value => setFilterOpen("statuses", value)}
      />
      <SearchFilter
        title="ITU Regions"
        name="itu-regions-filter"
        tooltip="Filter the search by ITU Region"
        value={ituRegionsOptions.filter(option => values.ituRegions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={ituRegionsOptions}
        loading={isItuRegionsLoading}
        disabled={!ituRegionsOptions.length}
        open={filters.ituRegions}
        onChange={value => {
          setFieldValue("ituRegions", mapValues(value));
          setFieldValue("regions", []);

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("ituRegions", value)}
      />
      <SearchFilter
        title="Main Regions"
        name="regions-filter"
        tooltip="Filter the search by Region"
        value={regionsOptions.filter(option => values.regions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={regionsOptions}
        loading={isRegionsLoading}
        disabled={!regionsOptions.length}
        open={filters.regions}
        onChange={value => {
          //console.log("Set region");
          setFieldValue("ituRegions", []);
          setFieldValue("regions", mapValues(value));
          dispatch(setSubRegions(value.map(item => item.id)));

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("regions", value)}
      />
      <SearchFilter
        title="Sub Regions"
        name="child-regions-filter"
        tooltip="Filter the search by Sub Region"
        value={filteredSubRegions.filter(option => values.childRegions.includes(option.id))}
        icon={<Public fontSize="small" />}
        options={filteredSubRegions}
        loading={isChildRegionsLoading}
        disabled={!filteredSubRegions.length}
        open={filters.childRegions}
        onChange={value => {
          setFieldValue("ituRegions", []);
          setFieldValue("childRegions", mapValues(value));
          setFieldValue("regions", mapValues(value));

          if (!value.length) {
            setFieldValue("countries", []);
          }
        }}
        setOpen={value => setFilterOpen("childRegions", value)}
      />
      <SearchFilter
        title="Country/Territories"
        name="countries-filter"
        tooltip="Filter by specific country"
        value={countriesOptions.filter(option => values.countries.includes(option.id))}
        icon={<Place fontSize="small" />}
        options={countriesOptions}
        loading={isCountriesLoading}
        disabled={!countriesOptions.length}
        open={filters.countries}
        onChange={value => setFieldValue("countries", mapValues(value))}
        setOpen={value => setFilterOpen("countries", value)}
      />

      <>
        <SearchFilter
          title="UE Category"
          name="ue-category-filter"
          tooltip="What User Equipment category the Network supports"
          value={ueOptions.filter(option => values.ue.includes(option.id))}
          icon={<Update fontSize="small" />}
          options={ueOptions}
          loading={ueIsLoading}
          disabled={!ueOptions.length}
          open={filters.ue}
          onChange={value => setFieldValue("ue", mapValues(value))}
          setOpen={value => setFilterOpen("ue", value)}
        />
        <FrequenciesFilter
          title="Frequencies"
          name="frequency-bands-filter"
          tooltip="Filter the search by Frequency band"
          value={[
            options1.filter(option => values.frequencyBands.includes(option.id)),
            options2.filter(option => values.frequencyBands.includes(option.id)),
            options3.filter(option => values.frequencyBands.includes(option.id)),
          ]}
          icon={<Waves fontSize="small" />}
          options={frequencyBandsOptions}
          loading={isFrequencyBandsLoading}
          disabled={!frequencyBandsOptions.length}
          open={filters.frequencyBands}
          setOr={value => setFieldValue("frequencyBandControl", value)}
          orValue={values.frequencyBandControl}
          onChange={value => setFieldValue("frequencyBands", mapValues(value.flat()))}
          setOpen={value => setFilterOpen("frequencyBands", value)}
        />

        <SpeedFilter
          title="Download Speed"
          name="download-speed"
          tooltip="Filter by the maximum download speed the network supports"
          icon={<GetApp fontSize="small" />}
          value={values.downloadSpeed}
          control={values.downloadSpeedControl}
          open={filters.downloadSpeed}
          setOpen={value => setFilterOpen("downloadSpeed", value)}
          setControl={value => setFieldValue("downloadSpeedControl", value)}
          onChange={value => setFieldValue("downloadSpeed", value)}
        />
        <RadioFilter
          title="Switch Off Technology"
          name="switchoffTechs-filter"
          tooltip="Filter by Switch Off Technology"
          value={switchoffTechnologiesOptions.find(option => option.id === values.switchoffTechs)}
          icon={<DeleteForever fontSize="small" />}
          options={switchoffTechnologiesOptions}
          loading={isSunsetTechnologiesLoading}
          open={filters.switchoffTechs}
          onChange={value => setFieldValue("switchoffTechs", value?.id)}
          setOpen={value => setFilterOpen("switchoffTechs", value)}
          //testId="switchoffFilter"
        />
        <SearchFilter
          title="Satellite Vendors"
          name="satelite-vendors"
          tooltip="Filter the search by Satellite Vendors"
          value={satVendorsOptions.filter(option => values.satVendors.includes(option.id))}
          icon={<SatelliteAltIcon fontSize="small" />}
          options={satVendorsOptions}
          loading={isSatVendorsLoading}
          disabled={!satVendorsOptions.length}
          open={filters.satVendors}
          onChange={value => {
            setFieldValue("satVendors", mapValues(value));
            setFieldValue("regions", []);

            if (!value.length) {
              setFieldValue("countries", []);
            }
          }}
          setOpen={value => setFilterOpen("satVendors", value)}
        />
        <SearchFilter
          title="Satellite NTN"
          name="satelitentn-filter"
          tooltip="Filter the search by Satellite NTN"
          value={satelitentnOptions.filter(option => values.satelitentn.includes(option.id))}
          icon={<SatelliteAltIcon fontSize="small" />}
          options={satelitentnOptions}
          loading={isSatNTNLoading}
          disabled={!satelitentnOptions.length}
          open={filters.satelitentn}
          onChange={value => {
            setFieldValue("satelitentn", mapValues(value));
          }}
          setOpen={value => setFilterOpen("satelitentn", value)}
        />
      </>
    </FilterList>
  );
};

export default PublicNetworksForm;
