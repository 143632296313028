import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, useTheme } from "@mui/material";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import { variants } from "../../theme/variants";
import allRoutes from "../../constants/routes";

export interface DetailTableRowType {
  headings: string[];
  rows: string[][];
}

export interface DetailTableProps {
  title?: string;
  maxWidth?: number;
  data: DetailTableRowType;
}

const DetailTable: React.FC<DetailTableProps> = ({ title, data, maxWidth = 800 }) => {
  const theme = useTheme();
  const useTableStyles = makeStyles(() => ({
    root: {
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderStyle: "solid",
      borderWidth: "0",
    },
  }));

  const useTableHeadStyles = makeStyles(() => ({
    root: {
      //backgroundColor: theme.palette.grey[400],
      //backgroundColor: "#ff9900", //theme.palette.grey[400], //"#ccc",
      backgroundColor: allRoutes.operators.color, 
      color: "white",
    },
  }));
  const useTableCellStyles = makeStyles(() => ({
    root: {
      borderWidth: "0 !important",
    },
  }));

  const tableStyles = useTableStyles();
  const tableHeadStyles = useTableHeadStyles();
  const cellStyles = useTableCellStyles();

  return (
    <Box marginY={16} maxWidth={maxWidth} className="detailTable">
      <TableContainer>
        {title && (
          <Box paddingBottom={4}>
            <Typography variant="h4">{title}</Typography>
          </Box>
        )}
        <Table classes={tableStyles} size="small">
          {data.headings.length > 0 && (
            <TableHead classes={tableHeadStyles}>
              <TableRow>
                {data.headings.map((item, index) => (
                  <TableCell key={index}>
                    <ThemeProvider theme={variants.DARK}>
                      <Typography variant="h6" color="textPrimary">
                        {item}
                      </Typography>
                    </ThemeProvider>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.rows.map((item, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 ? "#fff" : theme.palette.grey[50] }}>
                {item.map((item2, i) => (
                  <TableCell key={i} classes={cellStyles}>
                    <Typography>{item2}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailTable;
